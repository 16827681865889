import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { register, employerRegister } from '../../actions/auth';
import PropTypes from 'prop-types';
import { FaUserFriends, FaBuilding, FaGoogle } from 'react-icons/fa';
import iconFresher from "../../img/FresherApplicant.png";
import iconExperienced from "../../img/experiencedApplicant.png";
function Register({ setAlert, register, employerRegister, isAuthenticated, isEmployerAuthenticated }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    phoneNumber: '',
    city: '',
    userType: 'jobseeker',
    jobSeekerType: 'experienced'
  });
  const navigate = useNavigate();

  const { name, email, password, password2, phoneNumber, city, userType, jobSeekerType } = formData;

  useEffect(() => {
    console.log('Auth state:', { isAuthenticated, isEmployerAuthenticated, userType });
    if (isAuthenticated) {
      if (userType === 'jobseeker') {
        if (jobSeekerType === 'fresher') {
          navigate('/fresher-profile-creation');
        } else {
          navigate('/experienced-profile-creation');
        }
      }
    } else if (isEmployerAuthenticated) {
      navigate('/employer-profile-creation');
    }
  }, [isAuthenticated, isEmployerAuthenticated, userType, jobSeekerType, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Password does not match', 'danger');
    } else {
      try {
        let result;
        if (userType === 'employer') {
          result = await employerRegister({ name, email, password, phoneNumber, city });
        } else {
          result = await register({ name, email, password, phoneNumber, city, status: jobSeekerType });
        }
  
        if (result) {
          await new Promise(resolve => setTimeout(resolve, 100));
          if (userType === 'employer') {
            navigate('/employer-profile-creation');
          } else {
            if (jobSeekerType === 'fresher') {
              navigate('/fresher-profile-creation');
            } else {
              navigate('/experienced-profile-creation');
            }
          }
        }
      } catch (error) {
        console.error('Registration error:', error);
        setAlert('An error occurred during registration. Please try again.', 'danger');
      }
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'phoneNumber') {
      // Allow only numeric values and restrict length to 10 digits
      if (/^\d*$/.test(value) && value.length <= 10) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
     <div className="px-4 py-6 sm:mx-auto sm:w-full sm:max-w-2xl">

     <div className="bg-white py-8 px-4 shadow rounded-2xl sm:rounded-3xl sm:px-10">

          <h2 className="text-center text-3xl font-bold text-gray-900 mb-8">
            Create your HireHub's profile
          </h2>

          <div className="flex mb-6 bg-gray-100 p-1 rounded-md max-w-xs mx-auto">
            <button 
              onClick={() => setFormData({ ...formData, userType: 'jobseeker' })} 
              className={`py-2 px-4 flex items-center justify-center ${userType === 'jobseeker' ? 'bg-blue-600 text-white' : 'bg-transparent text-gray-600'} rounded-md text-sm font-medium transition-all duration-200 flex-1`}
            >
              <FaUserFriends className="mr-2 text-lg" />
              Job Seeker
            </button>
            <button 
              onClick={() => setFormData({ ...formData, userType: 'employer' })} 
              className={`py-2 px-4 flex items-center justify-center ${userType === 'employer' ? 'bg-blue-600 text-white' : 'bg-transparent text-gray-600'} rounded-md text-sm font-medium transition-all duration-200 flex-1`}
            >
              <FaBuilding className="mr-2 text-lg" />
              Employer
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-semibold text-gray-700">Full Name</label>
              <input
                id="name"
                type="text"
                placeholder="Enter your name"
                name="name"
                value={name}
                onChange={handleChange}
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-semibold text-gray-700">Email ID</label>
              <input
                id="email"
                type="email"
                placeholder="Enter your Email ID"
                name="email"
                value={email}
                onChange={handleChange}
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-semibold text-gray-700">Password</label>
              <input
                id="password"
                type="password"
                placeholder="Minimum 8 characters"
                name="password"
                value={password}
                onChange={handleChange}
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs sm:text-sm"
                minLength="8"
                required
              />
            </div>
            <div>
              <label htmlFor="password2" className="block text-sm font-semibold text-gray-700">Confirm Password</label>
              <input
                id="password2"
                type="password"
                placeholder="Confirm Password"
                name="password2"
                value={password2}
                onChange={handleChange}
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs sm:text-sm"
                minLength="8"
                required
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-semibold text-gray-700">Phone Number</label>
              <div className="mt-1 relative rounded-xl shadow-sm">
                <span className="absolute inset-y-0 left-0 flex items-center text-gray-500 text-xs sm:text-sm pl-3 pointer-events-none">
                  +91
                </span>
                <input
                  id="phoneNumber"
                  type="tel"
                  placeholder="Enter your phone number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleChange}
                  className="appearance-none block w-full pl-12 pr-3 py-2 border border-gray-300 rounded-xl shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs sm:text-sm"
                  required
                />
                
              </div>
            </div>

            {userType === 'jobseeker' && (
              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={() => setFormData({ ...formData, jobSeekerType: 'experienced' })}
                  className={`flex-1 p-3 ${jobSeekerType === 'experienced' ? 'bg-gray-200' : 'bg-white'} border border-gray-300 rounded-md text-left text-sm`}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="font-medium">I'm experienced</p>
                      <p className="text-gray-500 text-xs">I have work experience in various companies</p>
                    </div>
                    <img src={iconExperienced} alt="Experienced" className="h-12 w-12 ml-2" />
                  </div>
                </button>
              
                <button
                  type="button"
                  onClick={() => setFormData({ ...formData, jobSeekerType: 'fresher' })}
                  className={`flex-1 p-3 ${jobSeekerType === 'fresher' ? 'bg-gray-200' : 'bg-white'} border border-gray-300 rounded-md text-left text-sm`}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="font-medium">I'm fresher</p>
                      <p className="text-gray-500 text-xs">I am a student/ Haven't worked after graduation</p>
                    </div>
                    <img src={iconFresher} alt="Fresher" className="h-12 w-12 ml-2" />
                  </div>
                </button>
              </div>
            )}
            <div>
              <label htmlFor="city" className="block text-sm font-semibold text-gray-700">City</label>
              <input
                id="city"
                type="text"
                placeholder="Mention your city"
                name="city"
                value={city}
                onChange={handleChange}
                className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-xl shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs sm:text-sm"
                required
              />
            </div>
            <div className="flex justify-center items-center">
              <button type="submit" className="py-2 px-8 border border-transparent rounded-2xl shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Sign up
              </button>
            </div>

          </form>

          <p className="mt-6 text-center text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
              Log In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  employerRegister: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  isEmployerAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isEmployerAuthenticated: state.auth.isEmployerAuthenticated,
});

export default connect(
  mapStateToProps,
  { setAlert, register, employerRegister }
)(Register);
import React from 'react';
import { connect } from 'react-redux';
import { applyForJob } from '../../../actions/jobActions';
import { FaMapMarkerAlt, FaClock, FaGlobe, FaBuilding, FaIndustry, FaUsers, FaCalendarAlt } from 'react-icons/fa';

const JobOverlay = ({ job, onClose, applyForJob }) => {
  const handleApply = () => {
    applyForJob(job._id);
    onClose();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl h-[90vh] flex flex-col">
        <div className="sticky top-0 bg-white z-10 border-b border-gray-200">
          <div className="flex justify-between items-center p-6">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800">{job.jobTitle}</h2>
            <div className="flex items-center space-x-4">
              <button
                onClick={handleApply}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-semibold hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Apply Now
              </button>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-2xl focus:outline-none">
                &times;
              </button>
            </div>
          </div>
          <div className="flex flex-wrap items-center gap-4 px-6 pb-4 text-sm text-gray-600">
            <span className="flex items-center"><FaMapMarkerAlt className="mr-2 text-blue-500" />{job.location}</span>
            <span className="flex items-center"><FaClock className="mr-2 text-blue-500" />Posted: {formatDate(job.date)}</span>
            <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs font-semibold">Active</span>
          </div>
        </div>

        <div className="flex-grow overflow-y-auto p-6 space-y-6">
          <section>
            <h3 className="text-xl font-semibold mb-3 text-gray-800">Job Description</h3>
            <p className="text-gray-600 text-base leading-relaxed">{job.jobDescription}</p>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-3 text-gray-800">Key Responsibilities</h3>
            <ul className="list-disc pl-5 text-gray-600 space-y-2">
              {job.jobHighlights && job.jobHighlights.split('\n').map((highlight, index) => (
                <li key={index}>{highlight}</li>
              ))}
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold mb-3 text-gray-800">Skill & Experience</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 text-lg gap-4 text-gray-600">
              <p><strong>Experience:</strong> {job.experience} year(s)</p>
              <p><strong>Qualification:</strong> {job.qualification}</p>
            </div>
            <div className="mt-3">
              <strong className="text-gray-800">Key Skills:</strong>
              <div className="flex flex-wrap gap-2 mt-2">
                {job.keySkills && job.keySkills.map((skill, index) => (
                  <span key={index} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          </section>

          {job.employer && (
            <section className="bg-gray-50 p-4 rounded-lg">
              <h3 className="text-xl font-semibold mb-3 text-gray-800">Company Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600">
                <p className="flex items-center text-lg"><FaBuilding className="mr-2 text-blue-500" /><strong>Company: </strong> {job.employer.name}</p>
                <p className="flex items-center text-lg"><FaIndustry className="mr-2 text-blue-500" /><strong>Industry: </strong> {job.industry}</p>
                <p className="flex items-center text-lg"><FaUsers className="mr-2 text-blue-500" /><strong>Company Size: </strong> {job.employerProfile?.companyInfo?.size || 'Not specified'}</p>
                <p className="flex items-center text-lg"><FaCalendarAlt className="mr-2 text-blue-500" /><strong>Founded: </strong> {job.employerProfile?.companyInfo?.founded || 'Not specified'}</p>
              </div>
              {job.employerProfile?.companyInfo?.website && (
                <p className="mt-3 flex items-center text-blue-600">
                  <FaGlobe className="mr-2" />
                  <a href={job.employerProfile.companyInfo.website} target="_blank" rel="noopener noreferrer" className="hover:underline">
                    {job.employerProfile.companyInfo.website}
                  </a>
                </p>
              )}
            </section>
          )}
        </div>

        <div className="sticky bottom-0 bg-white border-t border-gray-200 p-6 flex justify-between items-center">
          <p className="text-2xl font-bold text-blue-600">{job.offeredSalary} LPA</p>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { applyForJob })(JobOverlay);


import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateJobseekerResume } from '../../../actions/jobseekerProfile';

const UploadResume = ({ profile, updateJobseekerResume }) => {
  const [loading, setLoading] = useState(false);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        alert('File size should not exceed 5MB');
        return;
      }

      setLoading(true);
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          await updateJobseekerResume(reader.result);
        } catch (error) {
          console.error('Error updating resume:', error);
        } finally {
          setLoading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleViewResume = () => {
    if (profile?.resume?.url) {
      window.open(profile.resume.url, '_blank');
    } else {
      alert('No resume uploaded yet.');
    }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h3 className="text-xl font-semibold mb-4">Resume Management</h3>
      
      <div className="space-y-4">
        <div className="w-full border-2 border-dashed border-gray-300 rounded-lg p-4">
          {profile?.resume?.url ? (
            <div className="text-center">
              <p className="text-sm text-gray-600 mb-2">Current Resume</p>
              <p className="text-xs text-gray-400 mb-4">
                {profile.resume.url.split('/').pop()}
              </p>
              <button
                onClick={handleViewResume}
                className="mb-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                View Current Resume
              </button>
            </div>
          ) : (
            <p className="text-center text-gray-500">No resume uploaded yet</p>
          )}

          <div className="mt-4">
            <label
              htmlFor="resume-upload"
              className="block w-full text-center cursor-pointer"
            >
              <div className="mt-2 flex justify-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
              </div>
              <span className="mt-2 block text-sm font-medium text-gray-900">
                {loading ? 'Uploading...' : 'Click to upload new resume'}
              </span>
            </label>
            <input
              id="resume-upload"
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
              className="hidden"
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

UploadResume.propTypes = {
  profile: PropTypes.object,
  updateJobseekerResume: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile
});

export default connect(mapStateToProps, { updateJobseekerResume })(UploadResume);




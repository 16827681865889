import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import JobSeekerDashboardHome from './DashboardHome';
import PropTypes from 'prop-types';
import Sidebar from '../../layout/Sidebar';
import JobSeekerProfile from '../../profile/ApplicantProfile/ApplicantProfile';
import { getCurrentJobseekerProfile } from '../../../actions/jobseekerProfile';
import UploadResume from './UploadResume';
import ResetPassword from './PasswordReset';
import LogOut from './Logout';
import AppliedJobs from './AppliedJobs';
import JobListings from './JobListings';
import { setAlert } from '../../../actions/alert';

const Spinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);


const JobSeekerDashboard = ({
  getCurrentJobseekerProfile,
  setAlert,
  auth: { isAuthenticated, jobSeeker, loading: authLoading },
  profile: { profile, loading },
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if auth is not loading and user is not authenticated
    if (!authLoading && !isAuthenticated) {
      const token = localStorage.getItem('token'); // Or however you store your auth token
      
      if (!token) {
        navigate('/login', { 
          state: { message: 'You have been logged out. Please log in again.' },
          replace: true 
        });
      }
    }
  }, [isAuthenticated, authLoading, navigate]);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await getCurrentJobseekerProfile();
        if (location.state?.profileCreated) {
          setAlert('Profile created successfully!', 'success');
          navigate(location.pathname, { replace: true, state: {} });
        }
      } catch (error) {
        if (retryCount < 3) {
          setTimeout(() => setRetryCount((prevCount) => prevCount + 1), 1000);
        }
      }
    };

    if (isAuthenticated) {
      fetchProfile();
    }
  }, [getCurrentJobseekerProfile, retryCount, location, setAlert, navigate, isAuthenticated]);

  if (loading) {
    return <Spinner />;
  }

  if (!loading && profile === null && retryCount >= 3) {
    return <div>Error loading profile. Please refresh the page.</div>;
  }

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      <div className="flex-1 md:ml-64 bg-white">
        <header className="bg-white shadow-sm p-3 md:hidden sticky top-0 z-10">
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="text-gray-500 focus:outline-none focus:text-gray-700"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </header>
        <main className="p-3 sm:p-4 md:p-6 bg-gray-50 min-h-screen">
          <div className="max-w-7xl mx-auto">
            <Routes>
              <Route index element={<JobSeekerDashboardHome jobSeeker={jobSeeker} profile={profile} />} />
              <Route path="profile" element={<JobSeekerProfile profile={profile} />} />
              <Route path="upload-resume" element={<UploadResume profile={profile} />} />
              <Route path="applied-jobs" element={<AppliedJobs />} />
              <Route path="job-listings" element={<JobListings />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="logout" element={<LogOut />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
};

JobSeekerDashboard.propTypes = {
  getCurrentJobseekerProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
    loading: state.auth.loading // Make sure this is tracked in your auth reducer
  },
  profile: state.profile,
});

export default connect(mapStateToProps, {
  getCurrentJobseekerProfile,
  setAlert,
})(JobSeekerDashboard);
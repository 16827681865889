import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function CompanySidebar({ isOpen, setIsOpen }) {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(() => {

    const path = location.pathname;
    if (path === '/company-dashboard/' || path === '/company-dashboard') {
      return 'dashboard';
    }
    const match = path.match(/\/company-dashboard\/(.+)/);
    return match ? match[1] : 'dashboard';
  });

  const handleItemClick = (item) => {
    setActiveItem(item);
    setIsOpen(false); 
  };

  useEffect(() => {

    const path = location.pathname;
    if (path === '/company-dashboard/' || path === '/company-dashboard') {
      setActiveItem('dashboard');
    } else {
      const match = path.match(/\/company-dashboard\/(.+)/);
      if (match) {
        setActiveItem(match[1]);
      }
    }
  }, [location.pathname]);

  return (
    <>
      <div
        className={`fixed inset-0 bg-white bg-opacity-75 z-20 md:hidden transition-opacity duration-300 ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsOpen(false)}
      ></div>
      <aside
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-16 transition-transform duration-300 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 bg-white shadow-lg`}
      >
        <div className="h-full px-4 py-6 overflow-y-auto">
          <ul className="space-y-4">
            <SidebarItem
              to="/company-dashboard/"
              icon="fas fa-th-large"
              text="Dashboard"
              isActive={activeItem === 'dashboard'}
              onClick={() => handleItemClick('dashboard')}
            />
            <SidebarItem
              to="/company-dashboard/profile"
              icon="fas fa-building"
              text="Company Profile"
              isActive={activeItem === 'profile'}
              onClick={() => handleItemClick('profile')}
            />
            <SidebarItem
              to="/company-dashboard/post-job"
              icon="fas fa-plus-circle"
              text="Post a new Job"
              isActive={activeItem === 'post-job'}
              onClick={() => handleItemClick('post-job')}
            />
            <SidebarItem
              to="/company-dashboard/manage-jobs"
              icon="fas fa-tasks"
              text="Manage Jobs"
              isActive={activeItem === 'manage-jobs'}
              onClick={() => handleItemClick('manage-jobs')}
            />
            <SidebarItem
              to="/company-dashboard/applicants"
              icon="fas fa-users"
              text="Applicants"
              isActive={activeItem === 'applicants'}
              onClick={() => handleItemClick('applicants')}
            />
            <SidebarItem
              to="/company-dashboard/reset-password"
              icon="fas fa-key"
              text="Reset Password"
              isActive={activeItem === 'reset-password'}
              onClick={() => handleItemClick('reset-password')}
            />
            <SidebarItem
              to="/company-dashboard/logout"
              icon="fas fa-sign-out-alt"
              text="Logout"
              isActive={activeItem === 'logout'}
              onClick={() => handleItemClick('logout')}
            />
          </ul>
        </div>
      </aside>
    </>
  );
}

const SidebarItem = ({ to, icon, text, isActive, onClick }) => (
  <li>
    <NavLink
      to={to}
      onClick={onClick}
      className={`flex items-center font-extralight p-2 rounded-lg transition duration-75 ${
        isActive ? 'bg-blue-50 text-blue-500' : 'text-gray-500 hover:bg-gray-100'
      }`}
    >
      <>
        <i className={`${icon} w-5 h-5 ${isActive ? 'text-blue-500' : 'text-gray-400'}`}></i>
        <span className="ml-3 text-lg font-extralight">{text}</span>
      </>
    </NavLink>
  </li>
);

export default CompanySidebar;
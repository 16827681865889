// components/jobseeker/JobListings.js

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getJobs } from '../../../actions/jobActions';
import JobOverlay from './JobOverlay';
import { FaSearch, FaChevronDown, FaMapMarkerAlt, FaClock, FaBriefcase, FaBookmark } from 'react-icons/fa';

const JobListings = ({ getJobs, job: { jobs, jobSearchLoading } }) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [jobType, setJobType] = useState('');
  const [experience, setExperience] = useState('');
  const [industry, setIndustry] = useState('');

  useEffect(() => {
    if (searchTerm || jobType || experience || industry) {
      getJobs({ searchTerm, jobType, experience, industry });
    }
  }, [getJobs, searchTerm, jobType, experience, industry]);

  const handleViewJob = (job) => {
    setSelectedJob(job);
  };

  const closeOverlay = () => {
    setSelectedJob(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  if (jobSearchLoading) {
    return <div className="text-center py-8">Searching for jobs...</div>;
  }

  return (
    <div className="bg-white rounded-xl min-h-screen">
      <div className="max-w-6xl mx-auto px-4 py-8">
        <h1 className="text-2xl font-semibold text-gray-900 mb-6">Available Jobs</h1>

        <div className="bg-white rounded-xl shadow-md p-4 mb-6">
          <div className="flex flex-wrap items-center gap-4">
            <div className="flex-grow flex items-center">
              <FaSearch className="text-gray-400 mr-3" />
              <input
                type="text"
                placeholder="Search jobs"
                className="w-full py-2 text-lg focus:outline-none"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="relative">
              <select
                value={jobType}
                onChange={(e) => setJobType(e.target.value)}
                className="appearance-none bg-transparent pl-4 pr-8 py-2 border rounded-xl text-sm text-gray-600 focus:outline-none">
                <option value="">Job type</option>
                <option value="full-time">Full-time</option>
                <option value="part-time">Part-time</option>
                <option value="contract">Contract</option>
                <option value="internship">Internship</option>
                <option value="freelance">Freelance</option>
              </select>
              <FaChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400 pointer-events-none" />
            </div>
            <div className="relative">
              <select
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                className="appearance-none bg-transparent pl-4 pr-8 py-2 border rounded-xl text-sm text-gray-600 focus:outline-none">
                <option value="">Experience</option>
                <option value="entry-level">Entry-level</option>
                <option value="mid-level">Mid-level</option>
                <option value="senior-level">Senior-level</option>
                <option value="lead">Lead</option>
                <option value="manager">Manager</option>
              </select>
              <FaChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400 pointer-events-none" />
            </div>
            <div className="relative">
              <select
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                className="appearance-none bg-transparent pl-4 pr-8 py-2 border rounded-xl text-sm text-gray-600 focus:outline-none">
                <option value="">Industry</option>
                <option value="technology">Technology</option>
                <option value="finance">Finance</option>
                <option value="healthcare">Healthcare</option>
                <option value="education">Education</option>
                <option value="marketing">Marketing</option>
              </select>
              <FaChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400 pointer-events-none" />
            </div>
          </div>
        </div>

        {/* Job Listings */}
        {searchTerm === '' ? (
          <div className="text-center py-8">
            <h2 className="text-lg font-semibold text-gray-600">🔍 Start your job search!</h2>
            <p className="text-gray-500 text-base">
              Enter keywords, select filters, and discover exciting job opportunities.
            </p>
          </div>
        ) : jobs && jobs.length > 0 ? (
          jobs.map((job) => (
            <div key={job._id} className="bg-white rounded-xl shadow-md p-4 mb-4 flex flex-col sm:flex-row items-start sm:items-center">
              <div className="flex items-center mb-4 sm:mb-0 sm:mr-4">
              {job.employerProfile?.companyInfo?.logo?.url ? ( 
              <img 
                src={job.employerProfile.companyInfo.logo.url} 
                alt={job.employerProfile.companyInfo.name || job?.employer?.name || 'Company'} 
                className="w-12 h-12 rounded-md object-cover" 
              />
            ) : (
              <div 
                className={`w-12 h-12 rounded-md flex items-center justify-center text-white font-bold text-xl`} 
                style={{ backgroundColor: '#6366F1' }}
              >
                {(job.employerProfile?.companyInfo?.name || job?.employer?.name || 'C')[0].toUpperCase()}
              </div>
            )}
              </div>
              <div className="flex-grow">
                <h2 className="text-lg font-semibold text-gray-900">{job.jobTitle}</h2>
                <div className="flex flex-wrap items-center text-sm text-gray-500 mt-1">
                  <div className="flex items-center mr-4">
                    <FaMapMarkerAlt className="mr-1" />
                    <span>{job.location}</span>
                  </div>
                  <div className="flex items-center">
                    <FaClock className="mr-1" />
                    <span>Posted on: {formatDate(job.date || new Date())}</span>
                  </div>
                </div>
                {job.employer && (
                  <p className="text-sm text-gray-600 mt-1">
                    Posted by: {job.employer.name} ({job.employer.city})
                  </p>
                )}
                <p className="mt-2 text-sm text-gray-600">{job.jobDescription.substring(0, 100)}...</p>
              </div>
              <div className="flex flex-col items-end mt-4 sm:mt-0">
                <span className="text-sm font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded flex items-center mb-2">
                  <FaBriefcase className="mr-1" />
                  {job.jobType}
                </span>
                <button
                  onClick={() => handleViewJob(job)}
                  className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 transition duration-300"
                >
                  View Job
                </button>
              </div>
              <FaBookmark className="text-yellow-500 ml-4 mt-4 sm:mt-0 cursor-pointer" />
            </div>
          ))
        ) : (
          <div className="text-center py-8">
            <h2 className="text-lg font-semibold text-gray-600">🔍 No jobs found.</h2>
            <p className="text-gray-500 text-base">
              Try adjusting your search or filters, and check back soon for new opportunities!
            </p>
          </div>
        )}
        {selectedJob && <JobOverlay job={selectedJob} onClose={closeOverlay} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  job: state.job
});

export default connect(mapStateToProps, { getJobs })(JobListings);
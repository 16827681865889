import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../actions/auth';

const LogOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleLogoutConfirmation = (confirmed) => {
    if (confirmed) {
      dispatch(logout());
      navigate('/login'); // Redirect to login page
    }
    setModalOpen(false);
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 bg-white rounded-lg">
      <div className="p-8 mt-6">
        <div className="max-w-3xl mx-auto px-4 py-8 bg-white rounded-lg">
          <p className="text-center mb-6">Are you sure you want to log out?</p>
          <div className="flex justify-center">
            <button
              onClick={() => setModalOpen(true)}
              className="bg-red-500 hover:bg-red-600 text-white font-semibold text-base py-2 px-4 rounded inline-flex items-center"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                ></path>
              </svg>
              Log out
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-sm mx-auto">
            <h2 className="text-lg font-semibold text-center mb-4">Confirm Logout</h2>
            <p className="text-center mb-6">Are you sure you want to log out?</p>
            <div className="flex justify-around">
              <button
                onClick={() => handleLogoutConfirmation(true)}
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
              >
                Yes
              </button>
              <button
                onClick={() => handleLogoutConfirmation(false)}
                className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogOut;
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';
import {
  GET_PROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  UPDATE_PROFILE,
  EMPLOYER_PROFILE_CREATE_SUCCESS,
  EMPLOYER_PROFILE_CREATE_FAIL
} from './types';


// Get current employer's profile
export const getCurrentEmployerProfile = () => async (dispatch) => {
  try {
    dispatch({ type: 'SET_LOADING', payload: true });
    console.log('Fetching employer profile...');

    const res = await axios.get('/api/employer/profile/me', {
     
      headers: { 'Cache-Control': 'no-cache' }

    });
    console.log('Employer profile fetched:', res.data);

    dispatch({
      type: GET_PROFILE,
      payload: { ...res.data, type: 'employer' }
    });
    console.log('Dispatched GET_PROFILE action');
  } catch (err) {
    console.error('Error fetching employer profile:', err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { 
        msg: err.response?.data?.msg || 'Server Error', 
        status: err.response?.status || 500 
      }
    });
    console.log('Dispatched PROFILE_ERROR action');
  }
};
// Create or update employer profile
export const createEmployerProfile = (profileData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    };

    localStorage.setItem('employerId', profileData.employerId);
    const res = await axios.post('/api/employer/profile', profileData, config);

    dispatch({
      type: EMPLOYER_PROFILE_CREATE_SUCCESS,
      payload: res.data
    });

    dispatch(setAlert('Employer profile created successfully', 'success'));
    return res.data;
  } catch (err) {
    if (err.response) {
      console.error('Error response:', err.response.data);
    }

    if (err.response && err.response.data && err.response.data.errors) {
      err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else {
      dispatch(setAlert('Error creating profile. Please try again.', 'danger'));
    }

    dispatch({
      type: EMPLOYER_PROFILE_CREATE_FAIL,
      payload: { msg: err.response ? err.response.statusText : 'Server Error', status: err.response ? err.response.status : 500 }
    });
    
    throw err;
  }
};

// Update employer profile
export const updateEmployerProfile = (profileData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    };

    // Handle company logo upload
    if (profileData.companyInfo && profileData.companyInfo.logo) {
      profileData.companyInfo.logo = profileData.companyInfo.logo.url || '';
    }

    const res = await axios.put('/api/employer/profile', profileData, config);

    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data
    });

    dispatch(setAlert('Employer profile updated successfully', 'success'));
    return res.data;
  } catch (err) {
    if (err.response) {
      console.error('Error response:', err.response.data);
    }

    if (err.response && err.response.data && err.response.data.errors) {
      err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    } else {
      dispatch(setAlert('Error updating profile. Please try again.', 'danger'));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response ? err.response.statusText : 'Server Error', status: err.response ? err.response.status : 500 }
    });
    
    throw err;
  }
};

// Clear profile
export const clearProfile = () => ({ type: CLEAR_PROFILE });
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { jobseekerLogin, employerLogin } from '../../actions/auth';

function Login({ jobseekerLogin, employerLogin, isAuthenticated, isEmployerAuthenticated, onClose, onLoginSuccess, redirectPath }) {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showEmployerLogin, setShowEmployerLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const modalRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation(); // Get location to access state

  const { email, password } = formData;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let success;
    if (showEmployerLogin) {
      success = await employerLogin({ email, password });
    } else {
      success = await jobseekerLogin({ email, password });
    }
    if (success) {
      if (onLoginSuccess) {
        onLoginSuccess();
      } else {
        onClose();
      }
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (isAuthenticated) {
    return redirectPath ? <Navigate to={redirectPath} /> : <Navigate to="/applicant-dashboard/" />;
  }
  if (isEmployerAuthenticated) {
    return <Navigate to="/company-dashboard" />;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
      <div ref={modalRef} className="bg-white rounded-lg w-full max-w-md shadow-2xl">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-3xl font-bold text-gray-900">Login</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="mb-6">
            <p className="text-sm text-gray-600">Don't have an account? 
              <button onClick={() => navigate('/register')} className="text-blue-600 font-semibold hover:underline ml-1">Register now</button>
            </p>
          </div>
          
          {/* Display the logout message if it exists */}
          {location.state?.message && (
            <div className="mb-4 text-sm text-red-500">
              {location.state.message}
            </div>
          )}

          <div className="flex space-x-2 mb-6">
            <button 
              onClick={() => setShowEmployerLogin(false)} 
              className={`flex-1 py-2 px-4 ${!showEmployerLogin ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-800'} rounded-md text-sm font-medium flex items-center justify-center`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              Job Seeker
            </button>
            <button 
              onClick={() => setShowEmployerLogin(true)} 
              className={`flex-1 py-2 px-4 ${showEmployerLogin ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-800'} rounded-md text-sm font-medium flex items-center justify-center`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
              </svg>
              Employer
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-gray-700 text-sm font-semibold mb-1" htmlFor="email">
                Email ID/Username
              </label>
              <input
                id="email"
                type="email"
                placeholder="Enter your Email ID/Username"
                name="email"
                value={email}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="relative">
              <label className="block text-gray-700 text-sm font-semibold mb-1" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter your Password"
                name="password"
                value={password}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <button 
                type="button" 
                className="absolute right-3 top-9 text-blue-600 text-sm font-medium"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? 'Hide' : 'Show'} 
              </button>
            </div>
            <button 
              type="submit" 
              className="w-full bg-blue-600 text-white p-3 rounded-md text-sm font-medium transition duration-300 hover:bg-blue-700"
            >
              Login
            </button>
           
            <div className="text-center text-sm text-gray-500">
              Or
            </div>
            <button type="button" className="w-full p-3 border border-gray-300 rounded-md flex items-center justify-center text-gray-700 text-sm font-medium transition duration-300 hover:bg-gray-50">
              <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                <path d="M1 1h22v22H1z" fill="none"/>
              </svg>
              Sign in with Google
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  jobseekerLogin: PropTypes.func.isRequired,
  employerLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  onLoginSuccess: PropTypes.func,
  redirectPath: PropTypes.string,
  isEmployerAuthenticated: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isEmployerAuthenticated: state.auth.isEmployerAuthenticated
});

export default connect(mapStateToProps, { jobseekerLogin, employerLogin })(Login);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAppliedJobs } from '../../../actions/jobActions';
import { FaMapMarkerAlt, FaClock, FaBriefcase, FaBookmark } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AppliedJobOverlay from './AppliedJob';

const AppliedJobs = ({ getAppliedJobs, job: { appliedJobs, loading } }) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const fetchJobs = async () => {
      await getAppliedJobs();
      setIsInitialLoad(false);
    };
    fetchJobs();
  }, [getAppliedJobs]);

  // Handle initial loading state
  if (loading || isInitialLoad) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      const date = new Date(dateString);
      return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    } catch (error) {
      return 'Invalid Date';
    }
  };

  const handleViewJob = (job) => {
    setSelectedJob(job);
  };

  const closeOverlay = () => {
    setSelectedJob(null);
  };

  // Ensure appliedJobs is an array
  const jobs = Array.isArray(appliedJobs) ? appliedJobs : [];

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      {loading || isInitialLoad ? (
        <div>Loading...</div>
      ) : (
        <>
          {jobs.length > 0 ? (
            <>
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">Your Applied Jobs</h2>
              {jobs.map((job) => (
                <div key={job._id} className="bg-white rounded-xl shadow-md p-4 mb-4 flex flex-col sm:flex-row items-start sm:items-center">
                  <div className="flex items-center mb-4 sm:mb-0 sm:mr-4">
                  {job.companyLogo ? (
                      <img src={job.companyLogo} alt={job.companyName} className="w-12 h-12 rounded-md object-cover" />
                    ) : (
                      <div className={`w-12 h-12 rounded-md flex items-center justify-center text-white font-bold text-xl`} style={{ backgroundColor: job.companyColor || '#6366F1' }}>
                        {job.companyName && job.companyName[0] ? job.companyName[0].toUpperCase() : '?'}
                      </div>
                    )}
                  </div>
                  <div className="flex-grow">
                    <h2 className="text-lg font-semibold text-gray-900">{job.jobTitle}</h2>
                    <div className="flex flex-wrap items-center text-sm text-gray-500 mt-1">
                      <div className="flex items-center mr-4">
                        <FaMapMarkerAlt className="mr-1" />
                        <span>{job.location}</span>
                      </div>
                      <div className="flex items-center">
                        <FaClock className="mr-1" />
                        <span>Posted on: {formatDate(job.postedDate)}</span>
                      </div>
                    </div>
                    <div className="flex items-center mt-2">
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold ${job.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                        Status: {job.status}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-end mt-4 sm:mt-0">
                    <span className="text-sm font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded flex items-center mb-2">
                      <FaBriefcase className="mr-1" />
                      {job.jobType}
                    </span>
                    <button
                      onClick={() => handleViewJob(job)}
                      className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 transition duration-300"
                    >
                      View Job
                    </button>
                  </div>
                  <FaBookmark className="text-yellow-500 ml-4 mt-4 sm:mt-0 cursor-pointer" />
                </div>
              ))}
            </>
          ) : (
            <div className="text-center py-8">
              <h2 className="text-lg font-semibold text-gray-600">📄 No Applied Jobs Found</h2>
              <p className="text-gray-500 mb-4">It looks like you haven't applied for any jobs yet.</p>
              <Link to="/applicant-dashboard/job-listings">
                <button className="bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-700 transition duration-300">
                  Start Searching for Jobs
                </button>
              </Link>
            </div>
          )}
        </>
      )}
      
      {selectedJob && (
        <AppliedJobOverlay 
          job={selectedJob} 
          onClose={closeOverlay}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  job: state.job
});

export default connect(mapStateToProps, { getAppliedJobs })(AppliedJobs);